import { useState, useEffect, useRef } from "react";
import { CircleFill } from "akar-icons";
import Select from "./select";
import { set } from "dot-object";

const SelectWrapper = ({
  name,
  value,
  options: initOptions,
  required,
  isDisabled,
  creatable,
  autoFocus,
  renderTrigger,
  isMulti,
  setValue,
  placeholder,
  onEditFieldChange,
  onChange,
  onKeyDown,
}) => {
  delete value?.colour;
  const [currentValue, setCurrentValue] = useState(value);
  useEffect(() => {
    setCurrentValue(value);
    setValue && setValue(name, value);
  }, [value]);

  const doChangeValue = (val) => {
    const newValue = Array.isArray(val) ? [...val] : { ...val };
    setCurrentValue(newValue);
    setValue && setValue(name, newValue);
    if (onEditFieldChange && !isMulti)
      onEditFieldChange(null, newValue?.label || newValue);
    if (onChange && !isMulti) {
      onChange(newValue);
    }
  };

  // if we're using a multi select, trigger change on blur instead
  const onBlur =
    isMulti && (onChange || onEditFieldChange)
      ? (val) => {
          if (onEditFieldChange) onEditFieldChange(null, val?.label || val);
          if (onChange) onChange(val);
        }
      : null;

  const options = initOptions?.map((option) => {
    let { label, value, colour } = option;
    if (colour) {
      const iconStyle = {
        color: colour,
        marginRight: "1rem",
      };
      label = (
        <>
          <CircleFill size={10} style={iconStyle} />
          {label}
        </>
      );
    }
    const returnOption = {
      label,
      value,
    };
    return returnOption;
  });

  return (
    <Select
      required={required}
      isDisabled={isDisabled}
      creatable={creatable}
      options={options}
      value={currentValue}
      autoFocus={autoFocus}
      className="select"
      menuPlacement="auto"
      placeholder={placeholder}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 60 }) }}
      menuPortalTarget={document.body}
      key={renderTrigger}
      isMulti={isMulti}
      onChange={doChangeValue}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    />
  );
};

export default SelectWrapper;
